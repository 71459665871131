// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Calendar from '../common/Calendar'
import SessionTemplatePicker from '../SessionTemplatePicker'
import { EntitiesSelector } from '../common/EntitiesSelector'
import GroupPeopleSelector from '../common/GroupPeopleSelector'
import { personDisplay } from '../../src/lib/peopleHelper'
import {
  getGroupOptions,
  getPeople,
  getPersonOptions,
  getTemplateOptions,
} from '../../src/lib/idb/common'

export default class NewSessionModal extends Component {
  static propTypes = {
    translations: PropTypes.object.isRequired,
    people: PropTypes.array.isRequired,
    onUpdate: PropTypes.func.isRequired,
    errors: PropTypes.object,
  }

  constructor(props) {
    super(props)

    this.state = {
      session: {
        date: null,
      },
    }
  }

  componentDidMount = async () => {
    const peopleParams = await getPersonOptions()
    const people = await getPeople()

    peopleParams.values = people.map(person => ({
      groups: person.groups.map(group => group.id),
      label: personDisplay(person),
      value: person.id.toString(),
    }))

    this.setState({
      templateParams: await getTemplateOptions(),
      groupParams: await getGroupOptions(),
      peopleParams,
    })
  }

  handleInputChange = event => {
    if (!event) return

    const { session } = this.state

    const target = event.target
    let value = target.type == 'checkbox' ? target.checked : target.value
    const name = target.name

    const newSession = {
      ...session,
      [name]: value,
    }

    if (name == 'group_id') {
      newSession.person_ids = []
    }

    this.setState({ session: newSession })
    this.props.onUpdate(newSession)
  }

  validSessionTemplates(sessionTemplates, date) {
    if (!date) return sessionTemplates

    return sessionTemplates.filter(template => {
      return !template.obsolescenceDate || template.obsolescenceDate >= date
    })
  }

  render() {
    const { translations, errors } = this.props
    const { session, templateParams, peopleParams, groupParams } = this.state

    return (
      <div className="sessions__index--modal">
        <div className="quantum-layout__field">
          <Calendar
            name={'date'}
            placeholder={translations.ubf.date}
            selected={session ? session.date : ''}
            onChange={this.handleInputChange}
            translations={translations}
          />
          {errors && errors.date ? (
            <div className="error">
              {
                translations.activerecord.errors.models.grading_session
                  .mandatory.date
              }
            </div>
          ) : null}
        </div>

        {templateParams?.sessionTemplates ? (
          <div className="quantum-layout__field">
            <SessionTemplatePicker
              sessionTemplates={this.validSessionTemplates(
                templateParams.sessionTemplates,
                session.date
              )}
              sessionTemplateName={
                translations.activerecord.attributes.sessions_for_grader
                  .template
              }
              sessionTemplateNoResultsText={templateParams.noResultsText}
              sessionTemplatePlaceholderText={templateParams.placeholder}
              sessionTemplateLabel={''}
              styleVersion={2}
              newUi={true}
              onUpdate={this.handleInputChange}
              mainClass={'quantum-layout__field'}
              selectClass={'quantum-layout__select'}
              prefixClass={'select'}
            />

            {errors && errors.template ? (
              <div className="error">
                {
                  translations.activerecord.errors.models.grading_session
                    .mandatory.template
                }
              </div>
            ) : null}
          </div>
        ) : null}

        {peopleParams?.values &&
          (UBF.personGroupsMandatory ? (
            <GroupPeopleSelector
              groupOptions={groupParams}
              peopleOptions={peopleParams}
              onUpdate={this.handleInputChange}
            />
          ) : (
            <EntitiesSelector
              async={true}
              currentValues={[]}
              info={peopleParams}
              mainClass="quantum-layout__field"
              onUpdate={this.handleInputChange}
              prefixClass="select"
            />
          ))}

        <div className="quantum-layout__field">
          {errors && errors.people ? (
            <div className="error">
              {
                translations.activerecord.errors.models.grading_session
                  .mandatory.people
              }
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}
